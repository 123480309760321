.Overview {
    padding-right: 5%;
    a {
        text-decoration: none;
    }
    h3 {
        text-align: start;
        margin-left: 10px;
        margin-bottom: 10px;
        margin-top: 20px;
        font-size: 20px;
    }
    

    .filter{
        display: flex;
        margin-right: 5px;
    }

    
    ::-webkit-scrollbar {
        display: none;
    }
}

.filterAndTokens{
    display: flex;
    align-items: center;
    ::-webkit-scrollbar {
        display: none;
    }
}

.container{
    overflow: hidden;
}

.tokens {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 10px;
    overflow-y: scroll;
    height: 37px;
}

@media only screen and (max-width: 668px) {
    .root {
        flex: 1 1 450px;
        min-width: auto;
    }

    .tokens{
        max-width: calc(100vw - 70px);
    }
}

@media only screen and (min-width: 669px) {
    .root {
        flex: 1 0 450px;
        min-width: 450px;
    }
}

@media only screen and (min-width: 1181px) {
    .Overview {
        padding-right: 10%;
    }
}

@media only screen and (max-width: 668px) {
    .Overview {
        padding-right: 10px;
    }
}

.root {
    display: flex;
    flex-direction: column;
    margin: 10px;
    box-shadow: 0px 2px 10px 0px rgba(176, 170, 165, 0.5) !important;
    border-radius: 10px !important;
    position: relative;

    .closedLabel {
        position: absolute;
        top: 10%;
        padding: 5px 10px;
        background: lightgrey;
        border-radius: 0px 10px 10px 0px;
    }

    .onlyLabel {
        position: absolute;
        top: 10%;
        right: 0;
        padding: 5px 10px;
        background: var(--primary-color);
        color: white;
        border-radius: 10px 0px 0px 10px;
    }
}

.restaurants {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    a {
        display: flex;
        flex: 1 1 450px;
    }

    .placeHolder {
        flex: 1 0 430px;
        margin: 10px;
        min-width: 450px;

    }
}

.notFound {
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
    svg {
        color: var(--primary-color);
        height: 50px;
        width: 50px;
        margin-bottom: 10px;
    }
}
