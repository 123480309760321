.root {
    min-height: calc(100vh - 64px - 208px - 20px);
    h1 {
        margin-top: 30px;
    }
    svg {
        font-size: 48px;
    }
    h2 {
        text-align: center;
    }
}
