.Toolbar {
  justify-content: space-evenly;
  background-color: var(--primary-color);
  color: white;
  z-index: 255;
}

.logo {
  flex: 1;
  display: flex;
}

.center {
  display: flex;
  flex: 1;
}

.actions {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  a {
    color: white;
    margin: 0 10px;
    font-weight: 600;
  }
}

.searchIcon {
  height: 100%;
  display: inline-block;
  vertical-align: text-top;
  color: black;
}
.title {
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.titleText {
  font-family: "Montserrat", sans-serif;
  font-size: 27px;
  color: white;
  margin: 0px;

  &.grey {
    color: #414142;
  }
}

.logoImg {
  width: 40px;
  height: 40px;
  margin-left: 5px;
  background-image: url("./BestelleckLogo.svg");
  background-size: cover;
  background-position: center;
}

.logoHunger {
  width: 220px;
  height: 40px;
  margin-right: 5px;
  background-image: url("./hunger-hunger-logo-text.png");
  background-size: cover;
  background-position: center;
}

.logoHungerSmall {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  background-image: url("./hunger-hunger.svg");
  background-size: cover;
  background-position: center;
}

.search {
  position: relative;
  border-radius: 10px;
  background: white;
  border-color: #b6adad;
  border-width: 1px;
  border-style: solid;
  margin: 0 auto;
}

.logout {
  cursor: pointer;
}

@media screen and (min-width: 0px) and (max-width: 820px) {
  .actions {
    div {
      display: none;
    }
    .menuButton {
      display: inline;
    }
  }
}

@media screen and (min-width: 820px) {
  .actions {
    div {
      display: initial;
    }
    .menuButton {
      display: none;
    }
  }
}


