.extras {
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 15px;
    background: white;
    border: 1px solid lightgray;
    border-radius: 10px;
}

.extraEntries {
    display: flex;
    flex-direction: column;
}

.extrasContainer {
    width: 100%;
}

.extraInput {
    width: 40%;
    padding: 0 20%;
}

.extraContainer {
    text-align: left;
    margin-bottom: 30px;

}

@media only screen and (max-width: 600px) {
    .extraContainer {
        text-align: left;
    }

    .options {
        margin-bottom: 40px;
    }
}

.sectionHeader {
    text-align: left;
    margin-bottom: 10px;
    border-bottom: 1px solid;
    padding-bottom: 2px;
    padding-left: 10px;
    font-weight: 600;
}

.more{
    margin: 0px 10px;
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 5px;
    font-weight: 600;

    div{
        margin-left: 5px;
        color:var(--secondary-color);
    }
}