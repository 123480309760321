.root {
    background: #f7f7f7;
}

.section {
    margin: 5px;
    margin-bottom: 20px;
    border: 1px solid black;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 2px 10px 0px rgba(176, 170, 165, 0.5) !important;
    border: #e9e9e9 1px solid;
    .header {
        font-size: 16px;
        font-weight: 600;
        background: #e1dedb;
        padding: 10px;
    }

    .content {
        padding: 10px;
        background-color: white;
    }
}
.time {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
