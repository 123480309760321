.root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px 15px;
}

.buttonFooterContainer {
    margin: -16px -24px;
    background: white;
    padding-bottom: 16px;
    border-top: lightgrey 1px solid;
    box-shadow: 0px 1px 8px 0px rgba(176, 170, 165, 0.5);
}

.button {
    text-transform: inherit;
    font-size: 18px;
    font-weight: 600;
    flex: 1;
    margin-left: 22px;
    border-radius: 20px;
    height: 46px;
    line-height: 18px;
}