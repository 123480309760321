.root{
    width: 100%;
    text-align: left;
}

.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    .half {
        display: flex;
        flex-grow: 1;
        flex-basis: 48%;
        div {
            background-color: white;
        }

        select{
            background-color: white;
        }
        
    }

    .select {
        height: 56px;
        label{
            color: var(--secondary-color);
        }
    }
    .quarter {
        display: flex;
        flex-grow: 1;
        flex-basis: 17%;
    }
}

@media only screen and (max-width: 600px) {
    .row {
        .half {
            flex-basis: 90%;
        }
        margin-bottom: 20px;
    }
    h1{
        font-size: 24px;
    }
}
