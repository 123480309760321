@media only screen and (max-width: 668px) {
    .root {
        flex: 1 1 450px;
        min-width: auto;
    }

    .Overview {
        .tokens {
            max-width: calc(100vw - 70px);
        }
    }
}

@media only screen and (min-width: 669px) {
    .root {
        flex: 1 0 450px;
        min-width: 450px;
    }
}

.root {
    display: flex;
    flex-direction: column;
    margin: 10px 5px;
    box-shadow: 0px 2px 10px 0px rgba(176, 170, 165, 0.5) !important;
    border-radius: 10px !important;
    position: relative;

    .labels {
        position: absolute;
        top: 10%;
        right: 0;
    }

    .closedLabel {
        padding: 5px 10px;
        background: lightgrey;
        border-radius: 0px 10px 10px 0px;
        left: 0;
        right: auto;
        margin-bottom: 10px;
    }

    .leftLabels {
        position: absolute;
        top: 10%;
        left: 0;
    }

    .preOrderLabel {
        padding: 5px 10px;
        background: #008a00;
        color: white;
        border-radius: 0px 10px 10px 0px;
        left: 0;
        right: auto;
    }

    .onlyLabel {
        padding: 5px 10px;
        background: var(--primary-color);
        color: white;
        border-radius: 10px 0px 0px 10px;
    }

    .couponLabel {
        padding: 5px 10px;
        background: var(--primary-color);
        color: white;
        border-radius: 10px 0px 0px 10px;
        margin-bottom: 10px;
    }
}

.image {
    object-fit: cover;
    height: 18vh;
    max-height: 160px;
    width: 100%;
    color: inherit !important;
    background-color: #ebebeb;
}

.imageClosed {
    width: 100%;
    height: 18vh;
    max-height: 160px;
    opacity: 0.5 !important;
    object-fit: cover;
}

.imageContainer{
    height: 18vh;
    max-height: 160px;
}

.logo {
    width: 60px;
    height: 60px;
    margin: auto 10px;
    flex-shrink: 0;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-position: center;
    background-position: center;
    object-fit: contain;
}

.logoContainer {
    display: flex;
    width: 80px;
    span{
        display: flex !important;
    }
    picture{
        display: flex;
    }
}

.details {
    display: flex;
    flex-direction: row;
    position: relative;
}

.content {
    flex: 1 1 auto;
    flex-direction: row;
    align-items: start;
    text-align: initial;
    h6 {
        font-size: 14px;
    }

    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h5 {
            font-size: var(--font-size-l);
            font-weight: 600;
        }
    }

    .subtitle {
        display: flex;
        align-items: center;
        .rating {
            margin-left: 1vw;
            color: orange;
            display: flex;
            align-items: center;
            .count {
                color: black;
                margin-left: 5px;
            }
            svg {
                margin-right: 5px;
            }
        }
    }
}

.footer {
    //display: flex;
    margin-top: 5px;
    bottom: 0;
    left: 16px;
}
.footerEntry {
    display: inline-block;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
    svg {
        vertical-align: bottom;
        font-size: 18px;
        margin: auto 5px;
        margin-left: 0;
    }
    margin-right: 5px;
}

@media only screen and (max-width: 350px) {
    .footerEntry {
        max-width: 150px;
        margin-right: 5px;
    }
}

.isClosed {
    filter: grayscale(1);
}

.paymentOptions {
    margin-top: 5px;
    margin-left: -5px;
}
