.background {
    position: relative;
    background-color: white;
    background-image: url('restaurant.jpg');
    background-size: cover;
    height: 75vh;
    background-position: center;
    .container {
        position: absolute;
        top: 10px;
        color: white;
        width: 100%;
    }
    h3 {
        margin-top: 10px;
        font-size: 30px;
        margin-bottom: 0px;
        span {
            color: #414142;
        }
    }
    h4 {
        margin-top: 0px;
        font-size: 20px;
    }
}

.background::before {
    content: '';
    width: 100%;
    height: 180px;
    position: absolute;
    right: 0;
    background-color: var(--primary-color);
    -webkit-transform: skewY(3deg);
    transform: skewY(3deg);
    -webkit-transform-origin: right;
    transform-origin: right;
}

.whyContainer {
    background-color: white;
    padding-bottom: 30px;
    padding-top: 30px;
    margin-top: 10px;
    h1 {
        margin-top: 0;
    }
    h3 {
        font-weight: normal;
        font-size: 28px;
    }

    h5 {
        font-weight: 600;
        font-size: 18px;
    }
    img {
        width: 60%;
    }
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .instructions {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)) !important;
        padding: 0 5% !important;
    }
    .media {
        height: 250px !important;
    }
    .background {
        h3,
        h4 {
            margin: 0 5%;
            width: 90%;
        }
    }
}

.instructions {
    display: grid;
    padding: 0 10%;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: 1fr;
    gap: 0px 10px;
    .card {
        margin: 10px;
        box-shadow: 0px 2px 10px 0px rgba(176, 170, 165, 0.5);

        p {
            margin-top: 10px;
        }
    }

    .media {
        height: 320px;
    }
}

.about {
    min-height: 300px;
    background-color: #f8f8f8;
    h1 {
        margin-top: 0;
        padding-top: 40px;
        font-weight: 500;
    }
    p {
        font-weight: 400;
        margin: 0 30%;

        font-size: 20px;
        span {
            font-weight: 500;
        }
    }
    @media screen and (min-width: 0px) and (max-width: 768px) {
        p {
            margin: 0 5%;
            padding-bottom: 20px;
        }
    }
}

.list {
    margin: 0 30%;
    margin-bottom: 50px;
    font-size: 20px;
    text-align: left;
    li {
        margin-top: 25px;
    }
}

@media screen and (max-width: 1100px) {
    .list {
        margin-left: 20%;
        margin-right: 20%;
    }
}

@media screen and (max-width: 850px) {
    .list {
        margin-left: 10%;
        margin-right: 10%;
    }
}

@media screen and (max-width: 600px) {
    .list {
        margin-left: 5%;
        margin-right: 5%;
    }
}

