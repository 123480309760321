.root {
    position: relative;
    @media only screen and (min-width: 751px) {
        .title {
            position: absolute;
            left: 15vw;
            top: 160px;
            text-align: left;
            padding: 17px 30px;
            margin-top: 13px;
            background: rgb(149 144 144 / 68%);
            margin-left: -30px;
            border-radius: 10px;
            h2 {
                font-size: 48px;
                font-weight: 600;
                margin-bottom: 10px;
                margin-top: 0;
            }
            h3 {
                margin-top: 0;
                font-size: 24px;
                font-weight: 500;
                margin-bottom: 0;
            }

            color: white;
        }
        .collapse {
            max-height: 400px;
            overflow-y: auto;
        }

        .infoContainer {
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            bottom: -70px;
            width: 79vw;
            height: auto;
            border-radius: 10px;
            background: white;
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5) !important;
            margin: auto 0;
            display: grid;
            grid-template-columns: 170px 3fr 1fr;
            grid-template-rows: 1fr;
            gap: 0px 10px;
            margin: 10px 0px;
            .logo {
                height: 100px;
                width: 100px;
                margin-left: auto;
                margin-right: auto;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                object-fit: contain;
            }

            .infoText {
                display: flex;
                flex-direction: column;
                text-align: left;
                justify-content: center;
                margin: 20px 0px;
                .infoLine {
                    margin-bottom: 5px;
                    display: flex;
                    align-items: center;
                }
                .preOrderSpan {
                    color: #008a00;
                }
                .times {
                    display: flex;
                    flex-direction: column;
                    margin-left: 5px;
                }
                svg {
                    vertical-align: bottom;
                    margin-right: 5px;
                    height: 20px;
                    width: 20px;
                }

                .rating {
                    color: orange;
                    margin-bottom: 5px;
                    display: flex;
                    align-items: center;
                    .count {
                        color: orange;
                        margin-left: 5px;
                    }

                    .countHover {
                        color: orange;
                        margin-left: 5px;
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }
            .buttons {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin: 10px;
                .search {
                    height: 50px;
                    padding-left: 0;
                    width: 170px;
                    margin-right: 10px;
                    div {
                        margin-right: 0px !important;
                    }
                    button {
                        padding: 12px;
                    }
                }
                button {
                    height: 50px;
                    padding: 5px 10px;
                }
                svg {
                    margin-right: 5px;
                }
            }
        }
    }
    @media only screen and (min-width: 1001px) {
        .infoContainer {
            .buttons {
                .search {
                    width: 250px;
                }
            }
        }
    }
    .inline {
        display: inline !important;
    }
}

.image {
    object-fit: cover;
    height: calc(min(70vh, 70vw));
    width: 100%;
}

@media only screen and (max-width: 1001px) {
    .root {
        .infoContainer {
            width: 89vw;
            margin: 10px 0px;
        }
    }
}

.more {
    color: orange;
    cursor: pointer;
    text-decoration: underline;
    text-align: left;
}

.descriptionEntry {
    margin-bottom: 0 !important;
    text-align: left;
    min-height: 14px;
}

@media only screen and (max-width: 750px) {
    .root {
        .title {
            position: absolute;
            left: 10px;
            text-align: left;
            padding: 10px 20px;
            margin-top: 13px;
            margin-right: 10px;
            background: rgba(149, 144, 144, 0.68);
            border-radius: 10px;
            h2 {
                font-size: 40px;
                font-weight: 600;
                margin-bottom: 10px;
            }
            h3 {
                margin-top: 0;
                font-size: 20px;
                font-weight: 500;
            }

            color: white;
        }

        .infoContainer {
            width: 100%;
            height: auto;
            border-radius: 0px;
            background: white;
            box-shadow: none;
            margin: auto 0;
            display: grid;
            margin: 0 0px;
            margin-top: -3px;

            .descriptionSmall {
                justify-content: flex-start !important;
            }

            .logo {
                height: 100px;
                width: 100px;
                margin-left: auto;
                margin-right: auto;
                border-radius: 100px;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }

            .infoTextSmall {
                margin-top: 20px;
                margin-left: 20px;
                margin-right: 20px;
                display: flex;
                flex-direction: column;
                .line {
                    margin-bottom: 10px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    .rating {
                        color: orange;
                        .count {
                            color: orange;
                            margin-left: 5px;
                        }

                        .countHover {
                            color: orange;
                            margin-left: 5px;
                            cursor: pointer;
                            text-decoration: underline;
                        }
                    }
                    svg {
                        vertical-align: bottom;
                        margin-right: 5px;
                        height: 20px;
                        width: 20px;
                    }
                }
                .openingTimes {
                    display: flex;
                    justify-content: flex-start;
                    .times {
                        display: flex;
                        flex-direction: column;
                        margin-left: 5px;
                    }
                    .preOrderSpan {
                        color: #008a00;
                    }
                    .openingTimesText {
                        text-align: left;
                    }
                }
            }

            .search {
                height: 40px;
                padding-left: 0;
                width: 100%;
                margin-top: 10px;
                margin-bottom: 10px;
                div {
                    margin-right: 0px !important;
                }
                button {
                    padding: 12px;
                }
            }
        }
    }
}

@media only screen and (max-width: 375px) {
    .root {
        .title {
            padding: 10px 10px;
            left: 5px;
        }
    }
}
