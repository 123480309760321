.Impressum {
    text-align: left;
    padding: 0 20%;
    margin-bottom: 30px;
}

@media screen and (min-width: 0px) and (max-width: 749px) {
    .Impressum {
      text-align: left;
      padding: 0 5%;
      margin-bottom: 50px;
      max-width: 100vw;
    }
  }
  
  @media screen and (min-width: 750px) and (max-width: 1000px) {
    .Impressum {
      text-align: left;
      padding: 0 10%;
      margin-bottom: 50px;
      max-width: 100vw;
    }
  }