.root {
    h1 {
        margin-top: 0px;
    }
    h2 {
        font-size: var(--font-size-l);
        font-weight: 600;
        text-align: left;
    }

    h3 {
        font-size: var(--font-size-l);
        font-weight: 600;
        margin-bottom: 10px;
        margin-top: 0;
    }
}

.rating {
    margin-bottom: 20px;
    svg {
        font-size: 48px;
        margin-right: 5px;
        cursor: pointer;
    }

    .good {
        color: rgb(12, 139, 48);
    }
    .neutral {
        color: orange;
    }
    .bad {
        color: rgb(215, 52, 26);
    }

    .good:hover {
        color: rgb(19, 203, 71);
    }
    .neutral:hover {
        color: rgb(253, 192, 79);
    }
    .bad:hover {
        color: rgb(240, 106, 85);
    }

    .selected {
        color: rgb(255, 218, 31);
    }
}

.orderItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .item {
        display: flex;
        flex-direction: row;
        text-align: left;
    }
    div {
        display: flex;
        flex-direction: column;
        text-align: left;
    }
    .extras {
        display: block;
        margin-left: 31px;
        font-size: var(--font-size-s);
    }
    .itemName {
        margin-left: 12px;
        font-weight: 600;
    }
    .amount {
        width: 2ch;
        font-weight: 600;
    }

    .label {
        font-weight: 600;
    }
}

hr {
    margin: 15px 0 !important;
}

.section {
    margin: 20px 0;
    border: 1px solid black;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 2px 10px 0px rgba(176, 170, 165, 0.5) !important;
    border: #e9e9e9 1px solid;
    .header {
        font-size: 18px;
        font-weight: 600;
        background: #e1dedb;
        padding: 10px;
        padding-left: 20px;
        margin-top: 0;
        margin-bottom: 0;
        display: flex;
        align-items: center;
    }

    .content {
        padding: 20px;
        padding-top: 10px;
        background-color: white;
        text-align: left;
        .extraText{
            margin-top: 10px;
            font-style: italic;
            font-size: 14px;
        }
    }
    .indicator {
        div {
            width: 14px;
            height: 14px;
            border-radius: 10px;
            margin-left: 10px;
        }

        .good {
            background-color: rgb(12, 139, 48);
        }

        .yellow {
            background-color: orange;
        }

        .red {
            background-color: rgb(215, 52, 26);
        }
    }
}

.line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.restaurantName {
    font-size: var(--font-size-l);
    margin-bottom: 10px;
    font-weight: bold;
    text-align: center;
}

.detailsHeader {
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: bold;
    text-align: left;
}

.infoLine {
    margin-bottom: 5px;
}

.button {
    margin: 20px;
    width: 20vw;
}

a {
    text-decoration: none;
}

@media only screen and (max-width: 800px) {
    .button {
        width: 200px;
    }
}
