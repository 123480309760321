.root {
    min-height: calc(100vh - 64px - 206px - 20px);
    margin-top: 20px;
    padding: 20px 30%;
}

@media only screen and (max-width: 1024px) {
    .root {
        padding: 20px 25%;
    }
}

@media only screen and (max-width: 800px) {
    .root {
        padding: 20px 20%;
    }
}

@media only screen and (max-width: 600px) {
    .root {
        padding: 20px 5%;
    }
}
