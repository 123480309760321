.Filter {
    .location {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        margin-top: 20px;
        svg {
            margin: auto 0;
        }
    }

    .buttons {
        font-weight: 600;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin-top: 11px;
        button {
            margin-left: 5px;
            margin-right: 5px;
            margin-bottom: 5px;
            display: flex;
            flex: 1 0 140px;
            min-width: 100px;
        }
    }
    hr {
        margin: 20px 10px !important;
    }
}

@media only screen and (max-width: 668px) {
    .locationForm {
        width: 80%;
        margin-right: 5px;
    }
}

@media only screen and (max-width: 883px) {
    .Filter {
        display: flex;
        align-content: space-between;
        justify-content: space-between;
        width: 100%;
        flex-direction: column;
    }
}

.MuiRadio-root {
    padding: 0;
}

.locationForm {
    svg {
        margin: 0 !important;
    }

    div {
        margin-left: 0;
        margin-right: 0;
    }

    .input{
        display: flex;
        flex: 1;
    }

}

.iconButton {
    padding: 0 !important;
    margin-left: 5px !important;
    margin-top: 20px !important;
}

.buttonFooter {
    position: fixed;
    margin: 0 !important;
    bottom: 0;
    z-index: 5;
    left: 0;
    height: 50px;
    width: 100vw;
    background: White;
    display: flex;
    .container {
        border-top: 2px solid var(--secondary-color);
    }

    .left {
        border-right: solid 1px #d2d2d2;
    }

    .containerSelected {
        border-top: 2px solid var(--primary-color);
    }

    .button {
        width: 50vw;
        height: 50px;
    }
}

.deliverySelect{
    margin: 0;
    margin-left: -10px;
    height: 50px;
    width: 100vw;
    background: White;
    display: flex;
    .container {
        border-bottom: 2px solid var(--secondary-color);
    }

    .left {
        border-right: solid 1px #d2d2d2;
    }

    .containerSelected {
        border-bottom: 2px solid var(--primary-color);
    }

    .button {
        width: 50vw;
        height: 50px;
    }
}

.filterContainer {
    display: flex;
    flex-direction: column;
}

.form {
    margin-bottom: 20px;
    margin-left: 5px;
    text-align: left;
    display: flex;
    span {
        font-size: 14px;
        padding-top: 2px;
        padding-bottom: 2px;
    }
    .rating{
        margin-top: 2px;
        span{
            font-size: 30px;
        }
    }
    .formLabel {
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        color: black !important;
    }
}
