.root{
    padding: 0px;
    overflow: hidden;
}

.image{
    width: 100%;
    height: auto;
    max-height: 80vh;
    margin-bottom: -5px;
}

.closeButton{
    position:absolute;
    top: 0;
    right: 0;
    padding: 2px;
}