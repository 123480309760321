.more {
    color: white;
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
    text-align: left;
    font-size: 14px;
}

.description {
    text-align: left;
    font-size: 14px;
}

.root {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}
