.root {
    margin-left: 5px;
    margin-right: 10px;
    display: flex;
    flex-basis: 100px;
    
    div {
        color: white;
        cursor: pointer !important;
    }

    div::before {
        border-bottom: 2px solid rgba(255, 255, 255, 1) !important;
    }

    svg {
        color: white;
    }
    .input {
        color: white;
    }

    .postalCode {
        color: white;
        padding: 4px 0 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        flex-grow: 1;
        flex-shrink: 1;
    }

    .icon {
        color: white;
        align-self: center;
        margin-right: 8px;
    }

    .postalCodeContainer {
        font-weight: 400;
        font-size: 1rem;
        flex-grow: 1;
        line-height: 1.4375em;
        display: flex;
        padding: 0 20px 0 10px;
        border-bottom: 2px solid rgba(255, 255, 255, 1);
        min-width: 0;
    }
}

@media screen and (max-width: 350px) {
    .root {
        width: 150px;
    }
}

@media screen and (min-width: 351px) {
    .root {
        width: 150px;
    }
}

@media screen and (min-width: 451px) {
    .root {
        width: 200px;
    }
}

@media screen and (min-width: 601px) {
    .root {
        width: 250px;
    }
}

@media screen and (min-width: 951px) {
    .root {
        width: 300px;
    }
}

@media screen and (min-width: 1051px) {
    .root {
        width: 400px;
    }
}