:root {
    --primary-color: #bf0417;
    --secondary-color: #414142;
    --tertiary-color: #26547c;
    --font-size-m: 18px;
    --font-size-s: 14px;
    --font-size-l: 20px;
}

.App {
    text-align: center;
    background-color: #f7f7f7;
    display: grid;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;
    max-width: 100vw;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

/* .MuiInputLabel-shrink {
  font-size: var(--font-size-m) !important;
} */

.MuiCardContent-root:last-child {
    padding-bottom: 16px !important;
}
