.checkbox {
    display: flex;
    flex: 1 1 100%;
    justify-content: space-between;
    width: 100%;
}

.priceLabel {
    white-space: nowrap;
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: flex-end;
}