@media only screen and (min-width: 669px) {
    .Main {
        display: grid;
        grid-template-columns: 1fr 5fr;
        grid-template-rows: 1fr;
        gap: 0px 30px;
        grid-template-areas: 'filter overview';
        padding-left: 5%;
        margin-top: 20px;
        margin-bottom: 50px;
    }
    .filter {
        grid-area: filter;
    }
    .overview {
        grid-area: overview;
        overflow: hidden;
    }
}

@media only screen and (max-width: 668px) {
    .Main {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        max-width: calc(100vw - 10px);
        overflow-x: hidden;
    }
}

@media only screen and (min-width: 1181px) {
    .Main {
        padding-left: 10%;
    }
}
