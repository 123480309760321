.content {
    display: flex;
    flex-direction: column;
}

.button{
    text-transform: none;
}

.filler {
    flex: 1;
}