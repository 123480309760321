.Checkout {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: 'main cart';
    margin: 0 5%;
    input {
        background-color: white !important;
    }
    margin-bottom: 50px;
    h3{
        text-align: left;
    }
    
}

.main {
    grid-area: main;
    .container {
        padding: 0 2.5%;
    }
}

.cart {
    margin-top: 50px;
    grid-area: cart;
    left: 75%;
    position: sticky;
    top: 20px;
    align-self: flex-start;
    margin-bottom: 20px;
    background: #f7f7f7;
    a {
        text-decoration: none;
    }
    button {
        text-transform: none;
    }
}

.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 30px;
}

.disclaimer {
    margin-top: 10px;
    text-align: left;
    color: gray;
    font-size: 14px;
}

.orderButton {
    margin-top: 20px;
    text-transform: none;
    font-size: 18px;
}

.paymentButton {
    width: 200px;
    height: 40px;
}

.payment {
    width: 100%;
    margin-bottom: 10px;
}

.cartSmall {
    margin-bottom: 20px;
    width: 100%;
}

@media only screen and (max-width: 750px) {
    .cart {
        position: fixed;
        top: inherit;
        bottom: 0;
        width: 100vw;
        margin-left: -5vw;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        margin-bottom: 0;
        border-radius: 0 !important;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    }

    .Checkout {
        display: flex;
        margin: 0 0%;
    }

    h1 {
        font-size: 24px;
    }
}

@media only screen and (max-width: 600px) {
    .orderButton {
        width: 100%;
        font-size: 16px;
        height: 50px;
    }
}

@media only screen and (max-width: 400px) {
    .Checkout {
        display: flex;
        margin: 0;
    }

    h1 {
        font-size: 24px;
    }
}
