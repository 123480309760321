.icons {
    display: flex;
    align-items: center;
    img {
        height: 24px;
        width: 24px;
        vertical-align: text-top;
        margin: 0;
        margin-bottom: 5px;
    }
    svg {
        height: 20px;
        width: 20px;
        vertical-align: text-top;
        color: var(--primary-color);
        margin-bottom: 5px;
    }

    .button {
        max-width: 120px;
        height: 50px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid grey;
        background: white;
        flex: 1 0 120px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 5px;
    }

    .selected {
        border-color: var(--primary-color);
        background-color: #cbe2ffa8;
    }
    .giro{
        width: auto;
    }

}

.giroSmall{
    height: 30px;
    width: 30px;
    vertical-align: text-top;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 3px;
    margin-bottom: 3px;


}

.payment{
    display: flex;
    flex-direction: row;
}

.paymentOptionsElements{
    display: flex;
    flex-direction: column;
}


.svg{
    margin: 5px 10px;
    height: 20px;
        width: 20px;
        vertical-align: text-top;
        color: var(--primary-color);
        margin-bottom: 5px;
}

.img {
    margin-right: 5px;
    margin-left: 8px;
    margin-top: 3px;
    margin-bottom: 3px;
    height: 24px;
    width: 24px;    
}



@media only screen and (max-width: 600px) {
    .row {
        .half {
            flex-basis: 90%;
        }
        div {
            background-color: white;
            display: flex;
            flex-direction: row;
            align-items: center;

        }
        
    }

    .token{
        display: flex;
        height: 30px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid grey;
        background: white;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        margin: 5px;
        margin-bottom: 10px;
    }

    .selected {
        border-color: var(--primary-color);
        background-color: #cbe2ffa8;
    }

    .icons {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        .button {
            max-width: 100%;
            display: flex;
            flex: 1;
            width: 90%;
            flex-direction: row;
            justify-content: flex-start;
            margin-left: 0px;
            margin-right: 0px;
            
        }
        svg{
            margin: 5px 10px;
        }

        img {
            margin: 5px 10px;
            margin-right: 5px;
            margin-left: 8px;        
        }
    }
}