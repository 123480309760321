.rootPrimary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary-color);
    color:white;
    padding: 7px 0 !important;
    h6{
        text-align: center;
    }
}

.rootSecondary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    color:color;
    padding: 7px 0 !important;
    h6{
        text-align: center;
    }
}

.closeButton{
    color:white !important;
}

.placeholder{
    width: 48px;
}