.menuCoupon{
    border: 2px solid var(--primary-color);
    height: 50px;
    width: calc(100% - 2px);
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d72c2c17;

    .text{
        color: var(--primary-color);
        font-size: 16px;
        font-weight: 600;
    }
    .subtext{
        color: var(--primary-color);
        font-size: 12px;
    }
}