.options {
    padding: 0 20%;
}

.optionGroup {
    width: 100%;
    margin-bottom: 40px !important;

    .select {
        margin-left: 10px;
        margin-right: 10px;
        background-color: #f7f7f7;
        border: 1px #424242 solid;
        border-radius: 10px;

        select {
            padding-left: 10px;
        }

        .svg {
            right: 5px;
        }
        svg {
            color: black;
        }
    }

    .select.Mui-selected {
        border: none;
        transition: none;
    }

    .select:after {
        border: none;
        transition: none;
    }

    .select:before {
        border: none !important;
        transition: none;
    }
}

.sectionHeader {
    text-align: left;
    margin-bottom: 10px;
    border-bottom: 1px solid;
    padding-bottom: 2px;
    padding-left: 10px;
    font-weight: 600;
}

.details {
    background: white;
    position: relative;

    svg {
        position: absolute;
        right: 0px;
        top: 0px;
        padding: 5px;
        font-size: 28px;
        border-left: #cfcfcf 1px solid;
        border-bottom: #cfcfcf 1px solid;
    }

    .info {
        position: inherit !important;
        font-size: 18px;
        padding: 0;
        border: none;
        margin-left: 3px;
        color: gray;
    }

    .info:hover {
        color: var(--tertiary-color);
    }
}

.MuiPaper-elevation1 {
    box-shadow: none;
}

@media (hover: hover) {
    .details:hover {
        background: #f2f2f1;
        box-shadow: 0 1px 1px #cfcac4;
        cursor: pointer;
    }
}

.details:active {
    background: #dddddc;
    box-shadow: 0 1px 1px #cfcac4;
    cursor: pointer;
}

.detailsExpanded {
    background: white;
    position: relative;
    cursor: pointer;

    svg {
        position: absolute;
        right: 0px;
        top: 0px;
        padding: 5px;
        font-size: 28px;
        border-left: #cfcfcf 1px solid;
        border-bottom: #cfcfcf 1px solid;
    }

    .info {
        position: inherit !important;
        font-size: 18px;
        padding: 0;
        border: none;
        margin-left: 3px;
        color: gray;
    }
}

.root {
    box-shadow: 0px 2px 8px 0px rgba(176, 170, 165, 0.5) !important;
    border: #e9e9e9 1px solid;
    text-align: left;
    border-radius: 10px;

    h5 {
        font-size: var(--font-size-m);
        font-weight: 600;
    }

    h6 {
        font-size: var(--font-size-s);
        line-height: 1.25rem;
    }
}

.productInfo {
    font-size: 12px;
    color: var(--tertiary-color);
    vertical-align: text-top;
}

.productInfo:hover {
    text-decoration: underline;
}

.disabled {
    color: gray !important;
    .price {
        color: gray !important;
    }
}

.rootExpanded {
    box-shadow: 0px 2px 10px 0px rgba(176, 170, 165, 0.5) !important;
    text-align: left;
    border-radius: 10px;

    h5 {
        font-size: var(--font-size-m);
        font-weight: 600;
    }

    h6 {
        font-size: var(--font-size-s);
    }
}

.expanded {
    text-align: center;

    button {
        text-transform: inherit;
        font-size: 16px;
    }
}

.root:hover {
    background: #dbdbdb;
    box-shadow: 0px 2px 10px 0px rgba(176, 170, 165, 0.5);
    border: #dbdbdb 1px solid;
    cursor: pointer;
}

.content {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 5px;
    margin-right: 30px;

    .price {
        color: var(--primary-color);
        font-size: var(--font-size-m);
        margin-top: 12px;
        font-weight: 600;
    }

    img {
        width: 150px;
        object-fit: cover;
        height: 120px;
        border-radius: 10px;
        margin-left: auto;
        cursor: zoom-in;
    }
}

.contentWithoutImage {
    margin-right: 30px;

    .price {
        color: var(--primary-color);
        font-size: var(--font-size-m);
        margin-top: 12px;
        font-weight: 600;
    }
}

@media only screen and (max-width: 600px) {
    .options {
        padding: 0 5%;
    }

    .content {
        img {
            width: 120px;
            height: 100px;
        }
    }
}

.title {
    display: flex;
    align-items: center;
}

.identifier {
    margin-right: 8px;
    border: 1px #636363 solid;
    padding: 1px 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    span{
        color: #636363;
        font-size: 14px;

    }
}
