.root{
    padding: 0px 0;
}

.price {
    color: var(--primary-color);
    font-size: var(--font-size-m);
    padding-top: 12px;
    font-weight: 600;
}

.image{
    max-height: 200px;
    width: 444px;
    object-fit: cover;
}



.text {
    padding: 0px 24px;
    padding-bottom: 12px;
    margin-bottom: 25px;
    background: white;
    border-bottom: lightgrey 1px solid;
    h6 {
        font-size: 18px;
    }
}



.optionContainer{
    padding: 0px 24px;
}

.button {
    text-transform: inherit;
    font-size: 16px;
}

.optionValue{
    display: flex;
    justify-content: space-between;
}


.optionGroup {
    width: 100%;
    margin-bottom: 30px;
    .select {
        margin-left: 10px;
        margin-right: 10px;
        background-color: white;
        border: 1px lightgray solid;
        border-radius: 10px;
        height: 40px;
        select {
            padding-left: 10px;
        }

        .svg {
            right: 5px;
        }
        svg {
            color: black;
        }
    }
    .select.Mui-selected {
        border: none;
        transition: none;
    }

    .select:after {
        border: none;
        transition: none;
    }
    .select:before {
        border: none !important;
        transition: none;
    }
}

.sectionHeader {
    text-align: left;
    margin-bottom: 10px;
    border-bottom: 1px solid;
    padding-bottom: 2px;
    padding-left: 10px;
    font-weight: 600;
}