.button {
    margin: 20px;
    width: 20vw;
}

@media only screen and (max-width: 800px) {
    .button {
        width: 200px;
    }
}
