.Footer {
    background-color: #ededed;
    display: grid;
    padding: 0 10%;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-template-rows: 1fr;
    padding-bottom: 30px;
    gap: 0px 0px;
    .card {
        background-color: #ededed;
        margin: 10px;
        box-shadow: none;
        a {
            text-decoration: none;
            color: black;
        }
        h6 {
            margin-bottom: 20px;
        }
    }

    .version{
        color: #ededed;
    }
}

.bullet {
    display: inline-block;
    margin: 0 2px;
    transform: scale(0.8);
}

.apps {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    img {
        margin-right: 10px;
        height: 50px;
        width: 150px;
        margin-bottom: 10px;
    }
}
