
@media only screen and (min-width: 884px) {
    .Filter {
        //  display: grid;
        //  grid-template-columns: 1fr 2fr 1fr;
    }
}

@media only screen and (max-width: 883px) {
    .Filter {
        display: flex;
        align-content: space-between;
        justify-content: space-between;
        width: 100%;
        flex-direction: column;
    }
}

.MuiRadio-root {
    padding: 0;
}

.deliverySelect{
    margin: 0;
    margin-left: -10px;
    height: 50px;
    width: 100vw;
    background: White;
    display: flex;
    .container {
        border-bottom: 2px solid var(--secondary-color);
    }

    .left {
        border-right: solid 1px #d2d2d2;
    }

    .containerSelected {
        border-bottom: 2px solid var(--primary-color);
    }

    .button {
        width: 50vw;
        height: 50px;
    }
}

.filterContainer {
    display: flex;
    flex-direction: column;
}

.form {
    margin-bottom: 20px;
    margin-left: 5px;
    text-align: left;
    display: flex;
    span {
        font-size: 14px;
        padding-top: 2px;
        padding-bottom: 2px;
    }
    .rating{
        margin-top: 2px;
        span{
            font-size: 30px;
        }
    }
    .formLabel {
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        color: black !important;
    }
}
