.icons {
    display:flex;
    align-items: center;
    img {
        height: 24px;
        width: 24px;
        vertical-align: text-top;
        margin: 0;
        margin-right: 5px;
    }
    svg {
        height: 20px;
        width: 20px;
        margin-right: 10px;
        margin-left: 5px;
        vertical-align: text-top;
        color: var(--primary-color);
    }
    .giro{
        height: 30px;
        width: 30px;
    }
}

.framed {
    img {
        height: 24px;
        width: 24px;
        vertical-align: text-top;
        margin: 0;
        padding: 8px;
        border: 1px solid #b8b8b8;
        border-radius: 5px;
        margin-right: 10px;
    }
    svg {
        height: 20px;
        width: 20px;
        margin-right: 10px;
        vertical-align: text-top;
        color: var(--primary-color);
        padding: 10px;
        border: 1px solid #b8b8b8;
        border-radius: 5px;
    }
}
