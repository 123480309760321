.root {
    display: flex;
    flex: 1;
    border-radius: 20px;
    margin-right: 10px !important;
    color: black !important;
    border: 1px gray solid !important;
    border-radius: 50px !important;
    text-transform: none;
}


.selected {
    display: flex;
    flex:1;
    border-radius: 20px;
    margin-right: 10px !important;
    color: white !important;
    border-radius: 50px !important;
    text-transform: none;
}

