.Restaurant{
    margin-bottom: 50px;
}

.main {
    grid-area: main;
}

.container{
    max-width: 100vw;
}

.menu {
    grid-area: menu;
    .menuContainer {
        position: sticky;
        top: 20px;
        text-align: left;
        .menuEntry {
            display: flex;
            flex-shrink: 0;
            margin: 5px;
            margin-right: 20px;
            margin-bottom: 10px;
            cursor: pointer;
        }
        .menuEntrySelected {
            cursor: pointer;
            display: flex;
            flex-shrink: 0;
            margin: 5px;
            margin-right: 20px;
            margin-bottom: 10px;
            font-weight: 600;
            color: var(--primary-color);
            //text-decoration: underline;
            //text-decoration-color: #bf0417;
        }
    }
}

.info {
    grid-area: info;
    height: calc(min(80vh, 80vw));
}

.checkout {
    grid-area: checkout;
    z-index: 5;
}


@media only screen and (max-width: 750px) {
    .checkout {
        position: fixed;
        top: inherit;
        bottom: 0;
        width: 100vw;
        margin-left: -5vw;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        margin-bottom: 0;
        border-radius: 0 !important;
        -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
    }


}

.menuCard {
    box-shadow: 0px 2px 8px 0px rgba(176, 170, 165, 0.5) !important;
    border: #e9e9e9 1px solid;
    border-radius: 5px;
    text-align: left;
    border-radius: 10px !important;
    display: flex;
}

@media only screen and (min-width: 601px) {
    .grid {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr 4fr 3fr;
        grid-template-rows: 1fr;
        gap: 0px 25px;
        margin: 0px 5vw;
        margin-top: 20px;
        grid-template-areas: "menu main checkout";
    }
}

@media only screen and (min-width: 1400px) {
    .grid {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr 4fr 2fr;
        grid-template-rows: 1fr;
        gap: 0px 25px;
        margin: 0px 10vw;
        margin-top: 20px;
        grid-template-areas: "menu main checkout";
    }
}

@media only screen and (max-width: 1001px) {
    .infoContainer {
        width: 90vw;
        margin: 10px 0;
    }
    .grid {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 4fr 3fr;
        grid-template-rows: 50px 1fr;
        gap: 0px 25px;
        margin: 0px 5vw;
        margin-top: 20px;
        grid-template-areas:
            "menu menu"
            "main checkout";
    }

    .menuCard {
        box-shadow: none !important;
        border: none;
        display: flex;
        border-radius: 0;
    }

    .menu {
        .menuContainer {
            display: flex;
            overflow-x: scroll;
            display: flex;
        }
        ::-webkit-scrollbar {
            display: none;
        }
    }
}

@media only screen and (max-width: 750px) {
    .Restaurant {
        .grid {
            display: flex;
            flex-direction: column;
        }

        .menu {
            position: sticky;
            top: -2px;
            background-color: #f7f7f7;
            z-index: 1;
            margin-left: -5vw;
            padding: 5px;
            padding-left: 20px;
            width: calc(95vw - 22px);
        }
    }

    .info {
        grid-area: info;
        height: auto;
    }
}
