$breakpoint-tablet: 768px;
$breakpoint-15: 1100px;

/*.background {
    background:rgb(201, 201, 201);
    background: linear-gradient(0deg, rgb(201, 201, 201) 0%, rgb(191, 30, 45) 100%);
    height: calc(100vh - 64px);
}*/

.background {
    position: relative;
    background-color: white;
    background-image: url('assets/burger.jpg');
    background-size: cover;
    background-position: center;
    height: 70vh;
    .container {
        position: absolute;
        top: 10px;
        color: white;
        width: 100%;
    }
    h3 {
        margin-top: 10px;
        font-size: 30px;
        margin-bottom: 5px;
        span {
            color: #414142;
        }
    }
    p {
        margin: 0;
    }
    @media screen and (min-width: 768px) {
        p {
            display: inline;
        }
    }
    h4 {
        margin-top: 20px;
        font-size: 20px;
    }
}

.backgroundWebp {
    background-image: url('assets/burger.webp');
}

.backgroundHungerWebp{
    background-image: url('assets/burgerHunger.webp');
}

.backgroundHunger{
    background-image: url('assets/burgerHunger.jpg');
}

.background::before {
    content: '';
    width: 100%;
    height: 180px;
    position: absolute;
    right: 0;
    background-color: var(--primary-color);
    -webkit-transform: skewY(3deg);
    transform: skewY(3deg);
    -webkit-transform-origin: right;
    transform-origin: right;
}

.locationForm {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.autoComplete {
    width: 350px;
}

.divider{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.listItem{
    background-color: white !important;
}

.notFound{
    background-color: white !important;
    cursor: default !important;
}

.groupName{
    color: black;
    font-family: "Lato", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 10px;
}

.listItem:hover{
    background-color: rgba(0, 0, 0, 0.04) !important;
}

.searchContainer {
    position: relative;
    display: inline-block;
    max-width: 600px;
    width: 80vw;
    margin-top: 50px;
    .searchButton {
        background-color: #26547c;
        color: white;
        height: 56px;
        font-weight: 600;
    }
    a {
        text-decoration: none;
    }
    .input {
        width: calc(100% - 28px);
    }
}

@media screen and (max-width: 768px) {
    .autoComplete {
        width: 300px;
    }
}

@media screen and (max-width: 400px) {
    .autoComplete {
        width: 250px;
    }
    .searchContainer {
        width: 90vw;
    }
}

.search {
    border-radius: 5px;
    background: #ffffff;
    min-width: 210px;
}

.instructions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 10%;
    @media (min-width: $breakpoint-15) {
        padding: 0 10%;
    }
    @media (min-width: 1215) {
        padding: 0 20%;
    }
    //grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    //grid-template-rows: 1fr;
    //gap: 0px 10px;
    .flexContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .card {
        min-width: 250px;
        width: 17vw;
        margin: 15px;
        box-shadow: 0px 2px 10px 0px rgba(176, 170, 165, 0.5);
        h5 {
            margin-top: 10px;
            font-size: 18px;
            font-weight: 600;
        }
        p {
            margin-top: 20px;
        }
    }
}

.instructionsContainer {
    background-color: #f8f8f8;
    margin-top: -28px;
    padding-bottom: 60px;
    h3 {
        padding-top: 28px;
        font-weight: normal;
        font-size: 28px;
    }
}

.app {
    height: 450px;
    background-color: var(--primary-color);
    text-align: initial;

    padding: 0 5%;
    @media (min-width: $breakpoint-tablet) {
        padding: 0 20%;
    }
    span {
        color: #414142;
        font-weight: 600;
    }

    .appInfo {
        height: auto;
        align-items: flex-start;
        div {
            margin: 0 auto;
        }
        p {
            margin: 0 auto;
        }
    }

    div {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        div {
            flex-direction: column;
            justify-content: initial;
            div {
                flex-direction: row;
                img {
                    margin-right: 10px;
                    height: 50px;
                    width: 150px;
                }
            }
        }
        h1 {
            margin-top: 0px;
            margin-bottom: 0px;
            color: white;
            font-weight: normal;
            padding: 10px;
        }
        h2 {
            margin-top: 0px;
            color: white;
            font-weight: normal;
            padding: 10px;
        }
        img {
            height: 400px;
            width: auto;
            flex-direction: column;
        }
        @media screen and (min-width: 0px) and (max-width: 768px) {
            .phone {
                display: none; /* dont show on small screens */
            }
        }
        p {
            color: white;
        }
    }
}

.error {
    align-items: center;
}

.icon {
    color: #26547c;
    width: 1.5em !important;
    height: 1.5em !important;
}

.whyContainer {
    background-color: white;
    padding-bottom: 94px;
    h3 {
        margin: 0;
        padding: 1em;
        font-weight: normal;
        font-size: 28px;
    }
}

.appContainer {
    height: 300px;
    background-color: white;
}
