.content {
    display: flex;
    flex-direction: row;
    .text {
        display: flex;
        flex-grow: 1;
        max-width: 80%;
        flex-direction: column;
        margin: 0 20px;
    }
}
