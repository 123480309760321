.root {
    min-height: calc(100vh - 64px - 206px - 20px);
    margin-top: 20px;
    text-align: left;
    padding: 0 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .header {
        margin: 5px 0;
        font-size: var(--font-size-m);
        font-weight: 600;
    }

    .time {
        font-size: var(--font-size-s);
        margin: 5px 0;
    }

    .rate {
        margin-top: 10px;
    }

    .select {
        width: 100%;
        height: 40px;
        margin-top: 5px;
    }

    .subHeader {
        margin: 5px 0;
        font-weight: 600;
    }

    .field {
        width: 100%;
    }

    .name {
        margin: 10px 0;
        label {
            color: black;
        }
    }

    .error {
        font-size: var(--font-size-s);
        color: red;
    }

    .notes {
        margin-top: 20px;
    }

    .button {
        width: 80%;
        margin: 10px 10%;
        text-transform: none;
    }

    .icon {
        margin-top: 30px;
        svg {
            width: 50px;
            height: 50px;
        }
    }
    .button {
        margin-top: 20px;
        margin-bottom: 70px;
        font-size: 18px;
    }
    a {
        text-decoration: none;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            margin-bottom: 5px;
            height: 150px;
            width: 150px;
        }
    }
}

.error {
    align-items: center;
}

@media only screen and (max-width: 1000px) {
    .root {
        padding: 20px 30%;
    }
}

@media only screen and (max-width: 800px) {
    .root {
        padding: 20px 20%;
    }
}

@media only screen and (max-width: 600px) {
    .root {
        padding: 20px 5%;
    }
}
