.card {
    width: 80%;
    margin: 0 10%;
}

@media only screen and (max-width: 750px) {
    .card {
        width: 100%;
        margin: 0;
    }
}

.content {
    display: flex;
}

.text {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.header {

    .headerText {
        margin-left: 15%;
        margin-top: 250px;
    }
}

.menu{
    margin: 10px 20px;
    display: flex;
    justify-content: space-between;
}

.groupImage{
    width: 80%;
    margin: 0 10%;
}

.itemCard{
    width: 80%;
    margin: 0 10%;
}

.placeholder{
    margin-bottom: 100px;
}