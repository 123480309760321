/* montserrat-800 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("montserrat-v18-latin-800.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("montserrat-v18-latin-800.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("montserrat-v18-latin-800.woff2") format("woff2"),
    /* Super Modern Browsers */ url("montserrat-v18-latin-800.woff")
      format("woff"),
    /* Modern Browsers */ url("montserrat-v18-latin-800.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("montserrat-v18-latin-800.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* lato-regular - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("lato-v20-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("lato-v20-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("lato-v20-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("lato-v20-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("lato-v20-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("lato-v20-latin-regular.svg#Lato")
      format("svg"); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("lato-v20-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("lato-v20-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("lato-v20-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("lato-v20-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("lato-v20-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("lato-v20-latin-700.svg#Lato")
      format("svg"); /* Legacy iOS */
}
