.MuiRadio-root {
    padding: 0;
}

.title{
    margin: 10px 0px;
    font-size: 16px;
    font-weight: 600;
}



.formLabel {
    text-align: left;
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 600;
    color: black !important;
}

.address{
    margin-top: 30px;
}

.section{
    display: flex;
    flex-direction: column;
}

.buttons2{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.button{
    margin-top: 30px;
    width: 60%;
    //margin-left: 25%;
    text-transform: none;
    font-size: 15px;
}

.cities{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttons{
    display: flex;
    flex-direction: row;
}

.postalCode{
    margin-top: 30px;
    width: 100%;
}
.postalCodeForm{
    width: 100%;
}
.deliveryPostalCode{
    margin: 30px 0;
    width: 100%;
}

.leftButton{
    border-radius: 4px 0px 0px 4px;
    width: 35%;
    height: 40px;
}

.rightButton{
    border-radius: 0px 4px 4px 0px;
    width: 35%;
}

@media only screen and (max-width: 601px) {
    .leftButton{
        width: 50%;
    }
    
    .rightButton{

        width: 50%;
    }
}

.header{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}