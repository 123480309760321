.root {
    display: flex;
    margin-top: 23px;
    svg {
        margin: 2px 10px;
        height: 24px;
        width: 24px;
        color: var(--primary-color)
    }
    .children{
        flex: 1;
    }
}
