@media screen and (min-width: 0px) and (max-width: 749px) {
  .Datenschutz {
    text-align: left;
    padding: 0 5%;
    margin-bottom: 50px;
    max-width: 100vw;
  }
}

@media screen and (min-width: 750px) and (max-width: 1000px) {
  .Datenschutz {
    text-align: left;
    padding: 0 10%;
    margin-bottom: 50px;
    max-width: 100vw;
  }
}

@media screen and (min-width: 1000px) {
  .Datenschutz {
    text-align: left;
    padding: 0 20%;
    margin-bottom: 50px;
    max-width: 100vw;
  }
}

.iframe {
  height: 200px;
  width: auto;
  border: 0;
}
