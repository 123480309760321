.root {
    background-color: white;
    margin: 20px 10px;
    box-shadow: 0px 2px 8px 0px rgba(176, 170, 165, 0.5);
    border: #e9e9e9 1px solid;
    border-radius: 5px;
    padding: 10px;
}

.space{
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
}