.title {
  text-decoration: none;
  color: black;
}

.container {
  display: inline-block !important;
}

.Header {
  background-color: var(--primary-color);
  svg {
    color: white;
  }
}

.List {
  background: #f8f8f8;
  ul {
    padding-top: 0;
  }
  span {
    margin-left: 10px;
  }
}

.logo {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logoImg {
  width: 40px;
  height: 40px;
  margin-left: 5px;
  background-image: url("../BestelleckLogo.svg");
  background-size: cover;
  background-position: center;
}

.logoHunger {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  background-image: url("../hunger-hunger.svg");
  background-size: cover;
  background-position: center;
}

.titleText {
  font-family: "Montserrat", sans-serif;
  font-size: 27px;
  color: white;
  margin: 0px;

  &.grey {
    color: #414142;
  }
}

.logoImg {
  width: 40px;
  height: 40px;
  margin-left: 5px;
}

.divider {
  background-color: grey !important;
  margin: 0 20px !important;
}
