.root {
    display: flex;
    flex-direction: column;
    margin:10px;
    margin-top: 0;
    .rating {
        
        display: flex;
        flex-direction: row;
        align-items: center;
        .count {
            font-size: var(--font-size-s);
            margin-left: 5px;
        }
    }

    hr {
        margin: 10px 15px !important;
    }

    h4 {
        margin: 0;
    }

    h2{
        margin-left: 5px;
    }
}
