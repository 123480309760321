.root{
    display: flex;
    align-items: center;
    .button{
        svg{
            font-size: 30px;
        }
        background-color: var(--primary-color);
        color: white;
    }

    .disabled{
        svg{
            font-size: 30px;
        }
        background-color: lightgray;
        color: white
    }
    .amount{
        font-size: 22px;
        font-weight: 600;
        width: 54px;
        text-align: center;
    }
}